import { useDispatch } from 'react-redux';
import { closePopup } from '../../../redux/slices/popupsSlice';
import { useTranslation } from '../../../context/LanguageProvider';
import { POPUPS_IDS } from '../configs/popup.configs';
import { Button } from '../../UI';
import s from './InactivityMessagePopup.module.scss';

const InactivityMessagePopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onOK = () => {
    dispatch(closePopup(POPUPS_IDS.INACTIVITY_MESSAGE));
  };

  return (
    <div className={s.background}>
      <div className={s.modal}>
        <div className={s.question}>
          <div>
            {t('This session has timed out and will start over.')}
          </div>
        </div>
        <div className={s.actions}>
          <Button onClick={onOK}>{t('OK')}</Button>
        </div>
      </div>
    </div>
  );
};

export default InactivityMessagePopup;
