import { useSelector } from 'react-redux';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Alert from './Alert/Alert';
import GlobalLoader from './Loaders/GlobalLoader';
import Wrapper from './Wrapper/Wrapper';
import { useSideEffects } from '../hooks/useSideEffects';
import Popups from './Popups/Popups';

function App() {
  const isConnected = useSelector(state => state.event.isConnected);
  const handle = useFullScreenHandle();

  useSideEffects();

  if (!isConnected) return <GlobalLoader />;
  return (
    <FullScreen handle={handle}>
      <Wrapper />
      <Alert />
      <Popups />
    </FullScreen>
  );
}

export default App;
