import { React, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import s from './CarouselPopup.module.scss';
import nextArrow from '../../assets/images/nextArrow.svg';
import prevArrow from '../../assets/images/prevArrow.svg';
import ZoomImage from '../ZoomImage/ZoomImage';
import ZMinus from '../../assets/nImages/human_z_minus.svg';
import ZPlus from '../../assets/nImages/human_z_plus.svg';

const CarouselPopup = ({
  files, smallCarousel, onClose,
}) => {
  const [ zoomLevel, setZoomLevel ] = useState(1);
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  });
  return (
    <div className={s.carouselPopup}>
      <div
        className={s.carouselPopupClose}
        onClick={() => {
          onClose();
        }} />
      <div className={classNames(s.carouselPopup__inner, smallCarousel && s.smallCarousel)}>
        <div className={s.carouselPopup__pic}>
          <Carousel
            showThumbs={isMobile}
            showStatus={false}
            swipeable={false}
            useKeyboardArrows
            showIndicators={false}
            renderArrowPrev={(clickHandler, hasPrev, labelPrev) => hasPrev && (
              <button
                className={
                  classNames(s.carouselPopup__sliderButton, s.carouselPopup__sliderButton__prevPos)
                }
                onClick={clickHandler}>
                <img
                  className={s.carouselPopup__sliderButton__img}
                  src={prevArrow}
                  alt='previous' />
              </button>
            )
            }
            renderArrowNext={(clickHandler, hasNext, labelNext) => hasNext && (
              <button
                className={
                  classNames(s.carouselPopup__sliderButton, s.carouselPopup__sliderButton__nextPos)
                }
                onClick={clickHandler}>
                <img
                  className={s.carouselPopup__sliderButton__img}
                  src={nextArrow}
                  alt='next' />
              </button>
            )
            }
            autoFocus
            showArrows>
            {files.map(
              item => (Object.prototype.hasOwnProperty.call(item, 'mozJpeg') ? (
                <div className={s.carouselPopup__imgContainer} key={item.name}>
                  <ZoomImage img={item} zoomLevel={zoomLevel} />
                </div>
              ) : (
                <div key={item.name}>
                  <ReactPlayer
                    url={process.env.REACT_APP_IMAGE_URL + item.videoUrl}
                    className='react-player fixed-bottom'
                    width='100%'
                    height='100%'
                    controls />
                </div>
              )),
            )}
          </Carousel>
          {!isMobile && (
            <div className={s.physical_settings}>
              <div className={s.ps_options}>
                <img
                  className={classNames(s.ps_zplus, { [s.zoom_disabled]: zoomLevel >= 2 })}
                  src={ZPlus}
                  alt='+'
                  onClick={() => zoomLevel <= 2 && setZoomLevel(
                    prevScale => Math.max(0.5, Math.min(prevScale * 1.25, 2)),
                  )}
                />
                <img
                  className={classNames(s.ps_zminus, { [s.zoom_disabled]: zoomLevel <= 1 })}
                  src={ZMinus}
                  alt='-'
                  onClick={() => zoomLevel >= 1 && setZoomLevel(
                    prevScale => Math.max(0.5, Math.min(prevScale * 0.8, 2)),
                  )}
                />
              </div>
            </div>
          )}
          <div
            className={s.carouselPopup__close}
            onClick={e => onClose()}>
            <svg className={s.carouselPopup__closeSvg}>
              <use xlinkHref="#close" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselPopup;
