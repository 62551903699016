import React, { useContext, createContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getKeyByValue } from '../helpers/utils';
import { selectKeywords, selectTranslation } from '../redux/slices/currentInterviewSlice';

const LanguageContext = createContext({});

const LanguageProvider = ({ children }) => {
  const translation = useSelector(selectTranslation);
  const keywords = useSelector(selectKeywords);

  const t = useCallback(text => translation[text] || text, [ translation ]);
  const reverse_t = useCallback(text => getKeyByValue(translation, text), [ translation ]);

  const key_t = useCallback(text => keywords[text] || text, [ keywords ]);
  const reverse_key_t = useCallback(text => getKeyByValue(keywords, text), [ keywords ]);

  return (
    <LanguageContext.Provider value={{
      t, reverse_t, key_t, reverse_key_t,
    }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useTranslation = () => useContext(LanguageContext);

export default LanguageProvider;
