import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { POPUPS_IDS } from '../../../components/Popups/configs/popup.configs';
import { openPopup } from '../../../redux/slices/popupsSlice';

export const useInterviewSideEffects = () => {
  const dispatch = useDispatch();
  const { isImageOpen } = useSelector(state => state.currentInterview);
  const isOnlyCase = useSelector(state => state.event.isOnlyCase);
  const choiceCount = useSelector(state => state.event.choiceCount);
  const inactivityTimer = useSelector(state => state.event.interval);
  const isCaseStarted = useSelector(state => state.currentInterview.started);

  useEffect(() => {
    if (isImageOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [ isImageOpen ]);

  useEffect(() => {
    if (+inactivityTimer && !isOnlyCase && isCaseStarted) {
      const timeoutId = setTimeout(() => {
        dispatch(openPopup({ id: POPUPS_IDS.INACTIVITY }));
      }, +inactivityTimer * 60 * 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [ inactivityTimer, isOnlyCase, choiceCount, isCaseStarted ]);
};
