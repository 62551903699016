import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../context/LanguageProvider';
import s from '../../pages/Interview/Templates/TableView/TableView.module.scss';
import { selectCurrentInterview } from '../../redux/slices/currentInterviewSlice';

const LabTemplate = () => {
  const { t } = useTranslation();
  const event = useSelector(selectCurrentInterview);
  const baseInfo = event.baseInfo ? event.baseInfo : {};
  return (
    <section>
      <div className={s.tvHeader}>
        <p>{t('Laboratory Test Requisition')}</p>
      </div>
      <div className={s.tvContainer}>
        <div className={s.lab_sheet_header}>
          <div className={s.lab_sheet_heading}>
            <div className={s.ls_header1}>{t('Patient Information')}</div>
            <div className={s.ls_header2}>{t('Ordering Physician')}</div>
          </div>
          <div className={s.lab_sheet_head_body}>
            <div className={s.lab_sheet_patient_info}>
              <div className={s.lab_patient_details}>
                <div className={s.lab_sheet_pi_holder}>
                  <div className={s.lab_sheet_pi_header}>{t('Title')}</div>
                  <div className={s.lab_sheet_pi_content}>{baseInfo.gender}</div>
                </div>
                <div className={s.lab_sheet_pi_holder}>
                  <div className={s.lab_sheet_pi_header}>{t('First Name')}</div>
                  <div className={s.lab_sheet_pi_content}>{baseInfo.firstName}</div>
                </div>
              </div>
              <div className={s.lab_sheet_pi_holder}>
                <div className={s.lab_sheet_pi_header}>{t('Age')}</div>
                <div className={s.lab_sheet_pi_content}>{baseInfo.age}</div>
              </div>
            </div>
            <div className={s.lab_sheet_physician_info}>
              <div className={s.lab_sheet_pi_holder}>
                <div className={s.lab_sheet_pi_content}>
                  {baseInfo.doctorTitle}&nbsp;{baseInfo.doctorName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LabTemplate;
