import { HUMAN_PARTS_NAMES, HUMAN_SIDES } from '../constants/physicalConstants';

const { LEFT, RIGHT } = HUMAN_SIDES;

const {
  CHEST, LUNGS, ABDOMEN, WRIST, EYE, NECK, SHOULDER, ANKLE, KNEE, WAIST, EAR, TONGUE,
  HAND, FOOT, BACK, GENITOURINARY, HEAD, LOWER_ARM, UPPER_ARM, LOWER_LEG, UPPER_LEG,
} = HUMAN_PARTS_NAMES;

export const HUMAN_PARTS_CONFIGS = {
  [HEAD]: {
    class: 'head',
  },
  [CHEST]: {
    double: true,
    class: { [LEFT]: 'chest2', [RIGHT]: 'chest1' },
  },
  [LUNGS]: {
    class: 'lungs',
  },
  [ABDOMEN]: {
    class: 'abdomen',
  },
  [WRIST]: {
    double: true,
    class: { [LEFT]: 'wrist2', [RIGHT]: 'wrist1' },
  },
  [EYE]: {
    double: true,
    class: { [LEFT]: 'eye2', [RIGHT]: 'eye1' },
  },
  [NECK]: {
    class: 'neck',
  },
  [SHOULDER]: {
    double: true,
    class: { [LEFT]: 'shoulder2', [RIGHT]: 'shoulder1' },
  },
  [ANKLE]: {
    double: true,
    class: { [LEFT]: 'ankle2', [RIGHT]: 'ankle1' },
  },
  [KNEE]: {
    double: true,
    class: { [LEFT]: 'knee2', [RIGHT]: 'knee1' },
  },
  [WAIST]: {
    class: 'waist',
  },
  [EAR]: {
    double: true,
    class: { [LEFT]: 'ear2', [RIGHT]: 'ear1' },
  },
  [TONGUE]: {
    class: 'tongue',
  },
  [HAND]: {
    double: true,
    class: { [LEFT]: 'hand2', [RIGHT]: 'hand1' },
  },
  [FOOT]: {
    double: true,
    class: { [LEFT]: 'foot2', [RIGHT]: 'foot1' },
  },
  [BACK]: {
    class: 'back',
  },
  [GENITOURINARY]: {
    double: true,
    class: { [LEFT]: 'genitourinary2', [RIGHT]: 'genitourinary1' },
  },
  [LOWER_ARM]: {
    double: true,
    class: { [LEFT]: 'lowerArm2', [RIGHT]: 'lowerArm1' },
  },
  [UPPER_ARM]: {
    double: true,
    class: { [LEFT]: 'upperArm2', [RIGHT]: 'upperArm1' },
  },
  [LOWER_LEG]: {
    double: true,
    class: { [LEFT]: 'lowerLeg2', [RIGHT]: 'lowerLeg1' },
  },
  [UPPER_LEG]: {
    double: true,
    class: { [LEFT]: 'upperLeg2', [RIGHT]: 'upperLeg1' },
  },
};
