import React, { useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import s from './ZoomImage.module.scss';

const ZoomImage = ({ img, zoomLevel }) => {
  const [ imageSize, setImageSize ] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  });

  useEffect(() => {
    let imageWidth = imageRef.current.clientWidth;
    let imageHeight = imageRef.current.clientHeight;
    const contWidth = containerRef.current.clientWidth;
    const contHeight = containerRef.current.clientHeight;
    if (zoomLevel > 1 && zoomLevel <= 2) {
      imageWidth *= Math.max(0.5, Math.min(zoomLevel * 1.25, 2));
      imageHeight *= Math.max(0.5, Math.min(zoomLevel * 1.25, 2));
      imageRef.current.style.marginLeft = `${
        imageWidth - contWidth > 0 ? imageWidth - contWidth + 100 * zoomLevel : 50
      }px`;
      imageRef.current.style.marginTop = `${
        imageHeight - contHeight > 0 ? imageHeight - contHeight + 100 * zoomLevel : 50
      }px`;
    } else if (zoomLevel < 1 && zoomLevel >= 0.5) {
      imageWidth *= zoomLevel;
      imageHeight *= zoomLevel;
      imageRef.current.style.marginLeft = imageWidth > contWidth
        ? `${imageWidth - contWidth}px` : `${contHeight - imageHeight}px`;
      imageRef.current.style.marginTop = imageHeight > contHeight
        ? `${imageHeight - contHeight}px` : `${contHeight - imageHeight}px`;
      if (imageHeight < contHeight && imageWidth < contWidth) {
        imageRef.current.style.marginLeft = 'auto';
        imageRef.current.style.marginTop = 'auto';
      }
    }
  }, [ zoomLevel ]);

  const handleImageLoad = (event) => {
    const contentElement = containerRef.current;
    const { naturalWidth, naturalHeight } = event.target;
    if (contentElement.clientWidth > naturalWidth && contentElement.clientHeight > naturalHeight) {
      setImageSize({ width: naturalWidth, height: naturalHeight });
    } else {
      setImageSize({
        width:
          contentElement.clientWidth < naturalWidth
            ? contentElement.clientWidth - 50
            : naturalWidth,
        height:
          contentElement.clientHeight < naturalHeight
            ? contentElement.clientHeight - 50
            : naturalHeight,
      });
    }
  };

  return (
    <div className={s.zoomImage}>
      <div ref={containerRef} className={s.zoomImage__pic}>
        <picture className={s.zoomImage__img_holder}>
          <source srcSet={process.env.REACT_APP_IMAGE_URL + img?.webp} type='image/webp' />
          <source srcSet={process.env.REACT_APP_IMAGE_URL + img?.mozJpeg} type='image/jpeg' />
          <img
            ref={imageRef}
            className={s.zoomImage__img}
            src={process.env.REACT_APP_IMAGE_URL + img.mozJpeg}
            alt={img.mozJpeg}
            onLoad={handleImageLoad}
            style={{
              transform: `scale(${zoomLevel})`,
              transition: 'transform 0.2s ease',
              // userSelect: 'none',
              // cursor: 'grab',
              width: isMobile ? 'auto' : imageSize.width,
              height: imageSize.height,
              marginLeft: 'auto',
              marginTop: 'auto',
            }}
          />
        </picture>
      </div>
      {/* {!isMobile && (
        <div className={s.physical_settings}>
          <div className={s.ps_options}>
            <img
              className={classNames(s.ps_zplus, { [s.zoom_disabled]: zoomLevel >= 2 })}
              src={ZPlus}
              alt='+'
              onClick={zoomImage}
            />
            <img
              className={classNames(s.ps_zminus, { [s.zoom_disabled]: zoomLevel <= 1 })}
              src={ZMinus}
              alt='-'
              onClick={unZoomImage}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ZoomImage;
