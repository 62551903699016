export const HUMAN_PARTS_NAMES = {
  CHEST: 'Chest',
  LUNGS: 'Lungs',
  ABDOMEN: 'Abdomen',
  WRIST: 'Wrist',
  NECK: 'Neck',
  SHOULDER: 'Shoulder',
  ANKLE: 'Ankle',
  KNEE: 'Knee',
  WAIST: 'Waist',
  EYE: 'Eye',
  EAR: 'Ear',
  TONGUE: 'Tongue',
  HAND: 'Hand',
  FOOT: 'Foot',
  BACK: 'Back',
  GENITOURINARY: 'Genitourinary',
  HEAD: 'Head',
  UPPER_ARM: 'Upper Arm',
  LOWER_ARM: 'Lower Arm',
  UPPER_LEG: 'Upper Leg',
  LOWER_LEG: 'Lower Leg',
};

export const HUMAN_SIDES = {
  LEFT: 'Left',
  RIGHT: 'Right',
  BOTH: 'Both or N/A',
};

export const HUMAN_POSITION = {
  FRONT: 'Front',
  BACK: 'Back',
};

export const PHYSICAL_ITEM_KEYS = {
  FRONT_BACK: 'Front/Back',
  EXAM: 'Physical Exam',
  SIDE: 'Side',
  OBSERVATION: 'Observation',
  IMAGE: 'Image',
};

export const PHYSICAL_ITEM_IDS = {
  CATEGORY: 0,
  FRONT_BACK: 1,
  EXAM: 2,
  SIDE: 3,
  OBSERVATION: 4,
  IMAGE: 5,
};

export const EXAMINATION_TYPES = {
  ADDITIONAL: 'additional',
  PHYSICAL: 'physical',
};
