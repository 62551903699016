import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUuidFromUrl } from '../helpers/utils';
import { selectActiveTab } from '../redux/slices/currentInterviewSlice';
import { setIsConnected } from '../redux/slices/eventSlice';
import { getCaseThunk, getEventThunk } from '../redux/thunks/eventThunk';
import { getIsShareableThunk } from '../redux/thunks/interviewThunk';

export const useSideEffects = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectActiveTab);
  const location = useLocation();
  const isOnlyCase = useSelector(state => state.event.isOnlyCase);

  useEffect(() => {
    const uuid = getUuidFromUrl();
    if (!uuid) {
      dispatch(setIsConnected());
    } else {
      const isCase = uuid.includes('case=');
      isCase
        ? dispatch(getCaseThunk({ uuid }))
        : dispatch(getEventThunk({ uuid }));
    }
  }, []);

  useEffect(() => {
    let intervalId;
    if (isOnlyCase) {
      intervalId = setInterval(() => {
        dispatch(getIsShareableThunk());
      }, 10000);
    }
    return () => clearInterval(intervalId);
  }, [ isOnlyCase ]);

  useEffect(() => {
    const setVhSize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVhSize();
    window.addEventListener('resize', setVhSize);
    window.addEventListener('scroll', setVhSize);
    window.addEventListener('orientationchange', setVhSize);
    return () => {
      window.removeEventListener('resize', setVhSize);
      window.removeEventListener('orientationchange', setVhSize);
    };
  }, [ location.pathname, activeTab ]);
};
