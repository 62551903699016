import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import s from './PhotoPopup.module.scss';
import ZMinus from '../../assets/nImages/human_z_minus.svg';
import ZPlus from '../../assets/nImages/human_z_plus.svg';

const PhotoPopup = ({ img, smallPhoto, onClose }) => {
  const [ zoomLevel, setZoomLevel ] = useState(1);
  const [ imageSize, setImageSize ] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  });
  const zoomImage = () => {
    if (zoomLevel >= 2) return;
    setZoomLevel(prevScale => Math.max(0.5, Math.min(prevScale * 1.25, 2)));
    const imageWidth = imageRef.current.clientWidth * Math.max(0.5, Math.min(zoomLevel * 1.25, 2));
    const imageHeight = imageRef.current.clientHeight
      * Math.max(0.5, Math.min(zoomLevel * 1.25, 2));
    const contWidth = containerRef.current.clientWidth;
    const contHeight = containerRef.current.clientHeight;
    imageRef.current.style.marginLeft = `${
      imageWidth - contWidth > 0 ? (imageWidth - contWidth) + 100 * zoomLevel : 50
    }px`;
    imageRef.current.style.marginTop = `${
      imageHeight - contHeight > 0 ? (imageHeight - contHeight) + 100 * zoomLevel : 50
    }px`;
  };

  const unZoomImage = () => {
    if (zoomLevel <= 1) return;
    setZoomLevel(prevScale => Math.max(0.5, Math.min(prevScale * 0.8, 2)));
    const imageWidth = imageRef.current.clientWidth * zoomLevel;
    const imageHeight = imageRef.current.clientHeight * zoomLevel;
    const contWidth = containerRef.current.clientWidth;
    const contHeight = containerRef.current.clientHeight;
    imageRef.current.style.marginLeft = imageWidth > contWidth
      ? `${imageWidth - contWidth}px` : `${contHeight - imageHeight}px`;
    imageRef.current.style.marginTop = imageHeight > contHeight
      ? `${imageHeight - contHeight}px` : `${contHeight - imageHeight}px`;
    if (imageHeight < contHeight && imageWidth < contWidth) {
      imageRef.current.style.marginLeft = 'auto';
      imageRef.current.style.marginTop = 'auto';
    }
  };

  const handleImageLoad = (event) => {
    const contentElement = containerRef.current;
    const { naturalWidth, naturalHeight } = event.target;
    if (contentElement.clientWidth > naturalWidth && contentElement.clientHeight > naturalHeight) {
      setImageSize({ width: naturalWidth, height: naturalHeight });
    } else {
      setImageSize({
        width: contentElement.clientWidth < naturalWidth
          ? contentElement.clientWidth - 50 : naturalWidth,
        height: contentElement.clientHeight < naturalHeight
          ? contentElement.clientHeight - 50 : naturalHeight,
      });
    }
  };

  return (
    <div className={s.photoPopup}>
      <div
        className={s.photoPopupClose}
        onClick={() => {
          onClose();
        }}
      />
      <div className={classNames(s.photoPopup__inner, smallPhoto && s.smallPhoto)}>
        <div
          ref={containerRef}
          className={s.photoPopup__pic}>
          <picture className={s.photoPopup__img_holder}>
            <source srcSet={process.env.REACT_APP_IMAGE_URL + img.webp} type='image/webp' />
            <source srcSet={process.env.REACT_APP_IMAGE_URL + img.mozJpeg} type='image/jpeg' />
            <img
              ref={imageRef}
              className={s.photoPopup__img}
              src={process.env.REACT_APP_IMAGE_URL + img.mozJpeg}
              alt={img.mozJpeg}
              onLoad={handleImageLoad}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.2s ease',
                // userSelect: 'none',
                // cursor: 'grab',
                width: isMobile ? 'auto' : imageSize.width,
                height: imageSize.height,
                marginLeft: 'auto',
                marginTop: 'auto',
              }}
            />
          </picture>
        </div>
        <div className={s.photoPopup__close} onClick={e => onClose()}>
          <svg className={s.photoPopup__closeSvg}>
            <use xlinkHref='#close' />
          </svg>
        </div>
        {!isMobile && (
          <div className={s.physical_settings}>
            <div className={s.ps_options}>
              <img
                className={classNames(s.ps_zplus, { [s.zoom_disabled]: zoomLevel >= 2 })}
                src={ZPlus}
                alt='+'
                onClick={zoomImage}
              />
              <img
                className={classNames(s.ps_zminus, { [s.zoom_disabled]: zoomLevel <= 1 })}
                src={ZMinus}
                alt='-'
                onClick={unZoomImage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoPopup;
