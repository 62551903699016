import { createSlice } from '@reduxjs/toolkit';
import { ALERT_TYPES } from '../../constants/names';

const initialState = {
  isActive: false,
  message: '',
  type: ALERT_TYPES.ERROR,
  closable: false,
  timer: 3000,
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (_, { payload }) => {
      const {
        message, type = initialState.type,
        closable = initialState.closable, timer = initialState.timer,
      } = payload;

      return {
        isActive: true,
        message,
        type,
        closable,
        timer,
      };
    },
    resetAlert: (state) => { state.isActive = false; },
  },
});

// ACTIONS
export const { setAlert, resetAlert } = alertSlice.actions;

export default alertSlice.reducer;
