import React from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import s from './VideoPopup.module.scss';

const VideoPopup = ({ video, smallVideo, onClose }) => (
  <div className={s.videoPopup}>
    <div
      className={s.videoPopupClose}
      onClick={() => {
        onClose();
      }}
    />
    <div className={classNames(s.videoPopup__inner, smallVideo && s.smallVideo)}>
      <div className={s.videoPopup__pic}>
        {video && video.map(vdo => (<div
          key={vdo.name}
          className={s.video_holder}>
          <ReactPlayer
            url={process.env.REACT_APP_IMAGE_URL + vdo.videoUrl}
            playing
            className='react-player fixed-bottom'
            width='100%'
            height='100%'
            loop
            controls
          />
        </div>))}
        <div className={s.videoPopup__close} onClick={e => onClose()}>
          <svg className={s.videoPopup__closeSvg}>
            <use xlinkHref='#close' />
          </svg>
        </div>
      </div>
    </div>
  </div>
);

export default VideoPopup;
