import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { closePopup, openPopup } from '../../../redux/slices/popupsSlice';
import { useTranslation } from '../../../context/LanguageProvider';
import { POPUPS_IDS } from '../configs/popup.configs';
import { Button } from '../../UI';
import s from './InactivityPopup.module.scss';
import { incrementChoiceCount } from '../../../redux/slices/eventSlice';
import { resetCaseThunk } from '../../../redux/thunks/eventThunk';
import { selectInterviewTranslationId } from '../../../redux/slices/currentInterviewSlice';

const InactivityPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { interval, isStartOverLoading } = useSelector(state => state.event);
  const caseId = useSelector(selectInterviewTranslationId);

  const onYes = () => {
    dispatch(closePopup(POPUPS_IDS.INACTIVITY));
    dispatch(incrementChoiceCount());
  };

  const onNo = () => {
    dispatch(resetCaseThunk({ caseId }))
      .unwrap()
      .then(() => {
        onYes();
        dispatch(openPopup({ id: POPUPS_IDS.INACTIVITY_MESSAGE }));
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(onNo, 60000);
    return () => clearTimeout(timeoutId);
  }, []);

  const title = t('You have been idle for more than /x/ minutes. Do you want to continue with this patient case?').replace('/x/', interval);

  return (
    <div className={s.background}>
      <div onClick={e => e.stopPropagation()} className={s.modal}>
        <div className={s.question}>
          <div>
            {title}
          </div>
        </div>
        <div className={s.actions}>
          <Button loading={isStartOverLoading} variant='danger' onClick={onNo}>No</Button>
          <Button onClick={onYes}>Yes</Button>
        </div>
      </div>
    </div>
  );
};

export default InactivityPopup;
