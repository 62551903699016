import { createSlice } from '@reduxjs/toolkit';
import { eventExtraReducers } from '../thunks/eventThunk';

const initialState = {
  isConnected: false,
  isOnlyCase: false,
  id: null,
  uuid: '',
  choiceCount: 0,
  interval: 5,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, { payload }) => ({ ...state, isConnected: true, ...payload }),
    resetEvent: () => initialState,
    updateInterview: (state, { payload }) => {
      const caseId = state.cases.findIndex(item => item.id === payload.id);
      if (caseId === -1) return;
      state.cases[caseId] = payload;
    },
    finishEvent: (state) => { state.finished = true; },
    setIsConnected: (state) => { state.isConnected = true; },
    incrementChoiceCount: (state) => { state.choiceCount += 1; },
  },
  extraReducers: eventExtraReducers,
});

// ACTIONS
export const {
  setEvent, resetEvent, setIsConnected, updateInterview, finishEvent, incrementChoiceCount,
} = eventSlice.actions;

// SELECTORS
export const selectEvent = state => state.event;

export default eventSlice.reducer;
