import classNames from 'classnames';
import React from 'react';
import s from './Checkbox.module.scss';

const Checkbox = ({ title, isDisabled, ...props }) => (
  <div className={classNames(s.checkbox, isDisabled ? s.isDisabled : '')}>
    <label className={s.checkbox__label} >
      {
        title && (<div className={s.checkbox__title}>{title}</div>)
      }
      <div className={classNames(s.checkbox__inner, { [s.checked]: props.checked })}>
        <input {...props} type="checkbox" className={s.checkbox__input} />
        {/* <div className={s.checkbox__checkmark}>
          <svg className={s.checkbox__svg}>
            <use xlinkHref="#ok" />
          </svg>
        </div> */}
      </div>
    </label>
  </div>
);

export default Checkbox;
