import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import s from './Presentation.module.scss';
import LeftArrow from '../../../../assets/nImages/arrow_left.svg';
import RightArrow from '../../../../assets/nImages/arrow_right.svg';
import { QUESTION_KEYS } from '../../../../constants/names';
import PhotoPopup from '../../../../components/PhotoPopup/PhotoPopup';
import CarouselPopup from '../../../../components/CarouselPopup/CarouselPopup';

const { CHAPTER_NAME } = QUESTION_KEYS;

const Presentation = ({ data }) => {
  const { answers, question } = data.data;
  const [ showPhoto, setShowPhoto ] = useState(null);
  const [ showPhotos, setShowPhotos ] = useState(null);
  const files = useSelector(state => state.currentInterview.files);
  const getPhotoPaths = name => files.find(item => item.name === name) || null;
  const [ activeImageIndex, setActiveImageIndex ] = useState(0);
  const [ zoomSlide ] = useState(false);
  const leftNavigation = () => {
    if (activeImageIndex !== 0) return setActiveImageIndex(activeImageIndex - 1);
  };
  const rightNavigation = () => {
    if (activeImageIndex !== answers.length - 1) return setActiveImageIndex(activeImageIndex + 1);
  };

  useEffect(() => {
    if (answers) {
      setActiveImageIndex(0);
    }
  }, [ answers ]);
  return (
    <section className={s.p_container}>
      <div
        className={classNames(s.p_left_arrow, { [s.p_left_visibilty]: activeImageIndex === 0 })}
        onClick={leftNavigation}>
        <img src={LeftArrow} alt='Left' name='leftArrow' />
      </div>
      <div className={s.p_holder}>
        {answers.map((d, index) => {
          const imageUrl = getPhotoPaths(d.image);
          return imageUrl?.mozJpeg && index === activeImageIndex ? (
            <div key={index} className={s.image_container}>
              <div className={s.image_header}>
                <div>
                  {question[CHAPTER_NAME]}&nbsp;{d.image}
                </div>
                <div>
                  {activeImageIndex + 1}/{answers.length}
                </div>
              </div>
              <div className={classNames(s.image_holder, { [s.zoom_image]: zoomSlide })}>
                <img
                  onClick={() => setShowPhoto(imageUrl)}
                  src={process.env.REACT_APP_IMAGE_URL + getPhotoPaths(d.image).mozJpeg}
                  alt={d.image}
                />
              </div>
            </div>
          ) : (
            index === activeImageIndex && (
              <div className={s.image_text_container}>{`Please upload ${d.image}`}</div>
            )
          );
        })}
      </div>
      <div
        className={classNames(s.p_right_arrow, {
          [s.p_right_visibilty]: activeImageIndex === answers.length - 1,
        })}
        onClick={rightNavigation}>
        <img src={RightArrow} alt='Right' name='rightArrow' />
      </div>
      {showPhoto && <PhotoPopup img={showPhoto} onClose={() => setShowPhoto(null)} />}
      {showPhotos && <CarouselPopup files={showPhotos} onClose={() => setShowPhotos(null)} />}
    </section>
  );
};

export default Presentation;
