import { INTERVIEW_TABS_CHAPTERS } from '../../../constants/names';
import anamnesis from './introductionAnamnesis.svg';

const {
  INTRODUCTION,
} = INTERVIEW_TABS_CHAPTERS;

export const MENU_IMAGES = {
  [INTRODUCTION]: anamnesis,
};
