import event from './slices/eventSlice';
import currentInterview from './slices/currentInterviewSlice';
import alertData from './slices/alertSlice';
import popups from './slices/popupsSlice';

export const reducers = {
  alertData,
  event,
  currentInterview,
  popups,
};
