import InactivityMessagePopup from '../InactivityMessagePopup/InactivityMessagePopup';
import InactivityPopup from '../InactivityPopup/InactivityPopup';
import TermsPopup from '../TermsPopup/TermsPopup';

// CONSTANTS
export const POPUPS_IDS = {
  TERMS: 1,
  INACTIVITY: 2,
  INACTIVITY_MESSAGE: 3,
};

// CONFIGS
const {
  TERMS, INACTIVITY, INACTIVITY_MESSAGE,
} = POPUPS_IDS;

export const POPUPS_CONFIGS = {
  [TERMS]: TermsPopup,
  [INACTIVITY]: InactivityPopup,
  [INACTIVITY_MESSAGE]: InactivityMessagePopup,
};
