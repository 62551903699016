import Loader from './Loader';
import s from './Loader.module.scss';

const GlobalLoader = () => (
  <div className={s.globalLoader}>
    <Loader />
  </div>
);

export default GlobalLoader;
